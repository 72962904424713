<template>
  <Paginate 
    :page-count="paginationData.totalPages" 
    :click-handler="handleClick" 
    :prev-text="'Previous'"
    :next-text="'Next'" 
    :container-class="'pagination'" 
    first-last-button 
    class="my-0"
    ></Paginate>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import axios from "axios";
import { ref, onBeforeMount } from "vue";
export default {
  components: {
    Paginate,
  },
  emits: ["paginationData"],
  props: ["pageType", "statusId", "socialMediaType", "creator", "dateAdded"],
  setup(props, ctx) {
    const list = ref([]);
    const paginationData = ref({});
    const getDataFromAPI = (currentPage) => {
      axios.get(getUrl(currentPage)).then(function (response) {
        list.value = response.data.data;
        paginationData.value = response.data.pagination;
        ctx.emit("paginationData", { data: list, pagination: paginationData });
      });
    };
    onBeforeMount(getDataFromAPI);
    const handleClick = (currentPage) => {
      getDataFromAPI(currentPage);
    };

    const getUrl = (currentPage) => {
      var url = "api/notifications/list?";

      if (currentPage) {
        var currentPageQuery = "pageNumber=" + currentPage;
        url += formatUrl(url, currentPageQuery);
      }
      if (props.statusId && props.statusId !== 0) {
        var statusQuery = "statusId=" + props.statusId;
        url += formatUrl(url, statusQuery);
      }

      if (props.socialMediaType && props.socialMediaType !== 0) {
        var socialMediaTypeQuery = "socialMediaType=" + props.socialMediaType;
        url += formatUrl(url, socialMediaTypeQuery);
      }

      if (props.creator !== "") {
        var creatorQuery = "creator=" + props.creator;
        url += formatUrl(url, creatorQuery);
      }

      if (props.dateAdded !== "") {
        var dateQuery = "dateAdded=" + props.dateAdded;

        url += formatUrl(url, dateQuery);
      }

      return url;
    };

    const formatUrl = (currentUrl, urlToAdd) => {
      if (currentUrl.substr(currentUrl.length - 1) === "?") {
        return urlToAdd;
      } else {
        return "&" + urlToAdd;
      }
    };

    return {
      handleClick,
      paginationData,
      getDataFromAPI,
      list,
      getUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 1rem;
  margin-left: auto;
}
</style>
